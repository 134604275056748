import React from "react"
import { css } from "theme-ui"
import LogoImg from "../../content/assets/logo.png"
import Nav from "./nav.js"
// import RightNav from "./rightNav.js"

export default function SiteHeader() {

  return (
    <div>
      <Nav />
      <div
        css={css({
          display: 'inline',
          marginLeft: '15%',
        })}
      ></div>
    </div>
  )
}