import React from "react"
import { Link, useStaticQuery, graphql, withPrefix } from "gatsby"
import { Styled, css } from "theme-ui"
import * as navStyles from "./nav.module.css"
import { OutboundLink } from "gatsby-plugin-gtag"

export default function Nav() {
  const data = useStaticQuery(
    graphql`
      query SiteMetaDataNav {
        site {
          siteMetadata {
            menuLinks {
              name
              url
              id
            }
          }
        }
      }
    `
  )
  const navLinks = data.site.siteMetadata.menuLinks;
	console.log('navStyles', navStyles)
	const toggle = () => {
		if(document.getElementById("list-top").style.transform == "none"){
			document.getElementById("list-top").style.transform = "translateX(-100%)";
		}else{
			document.getElementById("list-top").style.transform = "none";
		}
	}
  return (
		<nav className={navStyles.navTop} id="nav-top">
		  <div className={navStyles.containerFluid + ' d-flex align-items-center no-padding'}>
		    <ul className={navStyles.list + ' ' + navStyles.listTop + ' ' + "d-flex list-unstyled mb-0 mt-0"} id="list-top">
		    {navLinks.map(link => (
					<li>
						<Link to={link.url} id={link.id}>{link.name}</Link>
					</li>
		    ))}
		    </ul>
        { /*
		    <ul className={navStyles.list + ' ' + "d-flex list-unstyled  ml-auto mb-0 mt-0 pr-2"}>
		      <li>
		        <OutboundLink 
			        css={css({
			          fontFamily: `heading`,
			          fontWeight: `normal`,
			          fontSize: 5,
			          top: '-12px',
			          textDecoration: `none`,
			          ":hover": {
			            textDecoration: `underline`,
			          },
			        })}
		          href="https://store.theclothmask.com/product/79C25DCB2B1EFF44D19A3643960111154DAD"
		        >
		          Store
		        </OutboundLink>
		      </li>
				</ul>
          */ }
		    <button className="toggle-menu ml-2 d-md-none" aria-label="Responsive Navigation Menu" onClick={toggle}>
		      <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/hamburger.svg" alt="" />
		    </button>
		  </div>
		</nav>
  )
}