import React from "react"
import { css } from "theme-ui"

export default function SiteFooter() {

  return (
    <div
      css={css({
        mx: `auto`,
        px: 3,
        paddingTop: 2,
        paddingBottom: 2,
        background: 'rgba(86, 61, 124, 0.15)',
        fontFamily: 'Open Sans'
      })}
    >
      <a css={css({textDecoration: `none`, ":hover": {textDecoration: `underline`}, paddingRight: 4,})}
      href="mailto:support@theclothmask.com" name="ContactUs" title="Contact Us" target="_blank">
        Contact Us
      </a>
      <a css={css({textDecoration: `none`, ":hover": {textDecoration: `underline`},})}
      onClick={() => window.open('https://www.iubenda.com/privacy-policy/98357264', 
      'Privacy Policy', 'resizable,scrollbars,status,width=750,height=600')}
      href="#PrivacyPolicy" name="PrivacyPolicy" title="Privacy Policy">
        Privacy Policy
      </a>    
    </div>
  )
}